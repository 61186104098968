// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router";
import "./i18n";
import { Alert } from "@mui/material";
const Backoffice = React.lazy(() => import("./backoffice/backoffice"));

export function App() {
  const { i18n, t } = useTranslation();

  return (
    <LocalizationProvider
      adapterLocale={i18n.resolvedLanguage}
      dateAdapter={AdapterMoment}
    >
      <Routes>
        <Route
          path="/logout"
          element={
            <Alert sx={{ m: 2 }} severity="info">
              {t("logged_out")}
            </Alert>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense fallback={<p>Loading...</p>}>
              <Backoffice />
            </React.Suspense>
          }
        />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
